<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">{{ $t("ANALYTICS.GROUP_ATTENDANCE_TITLE") }}</h3>
      </div>
      <div class="card-toolbar">
        <!--begin::Button-->
        <b-button :disabled="!isTableAvailable"
                  class="btn bg-light-primary font-weight-bolder font-size-sm" @click="onExport">{{ $t("ANALYTICS.EXPORT_BUTTON") }}</b-button>
        <!--end::Button-->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin: Search Form-->
      <div class="mb-7">
        <div class="row align-items-center">
          <div class="col-lg-12 col-xl-12">
            <div class="row align-items-center">
              <div class="col-md-2 my-2 my-md-0">
                <label class="mr-3 d-none d-md-block">{{ $t("ANALYTICS.PERIOD_TITLE") }}</label>
                <treeselect v-model="filter_period"
                            :placeholder="$t('TITLE.ALL_FILTER_SELECT')"
                            :multiple="false"
                            @select="setPeriod"
                            :normalizer="normalizer"
                            :disabled="!isTableAvailable"
                            :clearable="false"
                            :limit="1"
                            :limitText="count => $t('TITLE.AND_SELECT') + ` ${count} ` + $t('TITLE.MORE_SELECT')"
                            :options="periodList">
                  <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
                </treeselect>
              </div>
              <div class="col-md-2 my-2 my-md-0">
                <label class="mr-3 d-none d-md-block">{{ $t("ANALYTICS.OFFICES_TITLE") }}</label>
                <treeselect v-model="filter_offices"
                            :placeholder="$t('TITLE.ALL_FILTER_SELECT')"
                            :multiple="false"
                            :normalizer="normalizer"
                            :limit="1"
                            :limitText="count => $t('TITLE.AND_SELECT') + ` ${count} ` + $t('TITLE.MORE_SELECT')"
                            :options="officesList">
                  <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
                </treeselect>
              </div>
              <div class="col-md-2 my-2 my-md-0">
                <label class="mr-3 d-none d-md-block">{{ $t("ANALYTICS.FLO0RS_TITLE") }}</label>
                <treeselect v-model="filter_floors"
                            :placeholder="$t('TITLE.ALL_FILTER_SELECT')"
                            :multiple="true"
                            :normalizer="normalizer"
                            :disabled="!filter_offices"
                            :limit="1"
                            :limitText="count => $t('TITLE.AND_SELECT') + ` ${count} ` + $t('TITLE.MORE_SELECT')"
                            :options="office.floors">
                  <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
                </treeselect>
              </div>
              <div class="col-md-3 my-2 my-md-0">
                <label class="mr-3 d-none d-md-block">{{ $t("TITLE.GROUPS_TITLE") }}</label>
                <treeselect v-model="filter_groups"
                            :placeholder="$t('TITLE.ALL_FILTER_SELECT')"
                            :multiple="true"
                            :normalizer="normalizer"
                            :disabled="!isTableAvailable"
                            :limit="1"
                            :limitText="count => $t('TITLE.AND_SELECT') + ` ${count} ` + $t('TITLE.MORE_SELECT')"
                            :options="groupsList">
                  <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
                </treeselect>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end: Search Form-->
      <template v-if="isTableAvailable">
        <!--begin::Table-->
        <div class="table-responsive">
          <b-table
              id="groups-attendance-table"
              bordered
              class="table-vertical-center"
              show-empty
              :items="attendanceGroups"
              :fields="setFields">
            <template #empty="scope">
              <h6>{{ $t("TABLE.EMPTY_TITLE") }}</h6>
            </template>
            <template #head(name, types)="data">
              <span class="pl-0 text-muted font-weight-bolder">{{ data.label }}</span>
            </template>
            <template #head()="data">
              <span class="pl-0 text-muted font-weight-bolder">{{ data.field.label }}</span>
            </template>
            <template #cell(name)="data">
              <h6 class="font-weight-bolder truncate-long-text text-dark mb-0"
                  :title="data.item.name">
                {{ data.item.name }}
              </h6>
            </template>
            <template #cell(types)="data">
              <template v-if="data.item.id !== 1">
                <div><h6 class="text-dark py-4 mb-0">{{ $t("ANALYTICS.EMPLOYEES_NUMBER_TABLE_TITLE") }}</h6></div>
                <div><h6 class="text-dark py-4 mb-0">{{ $t("ANALYTICS.QUOTA_TABLE_TITLE") }}</h6></div>
              </template>
              <template v-else>
                <h6 class="font-weight-bolder text-dark mb-0">{{ $t("ANALYTICS.EMPLOYEES_NUMBER_TABLE_TITLE") }}</h6>
              </template>
            </template>
            <template #cell()="data">
              <template v-if="data.item.id !== 1">
                <div v-for="(day, index) in data.item.data" :key="index">
                  <template v-if="isSameWeekDay(day, data)">
                    <div><h6 class="text-dark p-4 mb-0">{{ day.count }}</h6></div>
                    <div><h6 class="text-dark p-4 mb-0">{{ day.share + "%" }}</h6></div>
                  </template>
                </div>
              </template>
              <template v-else>
                <div v-for="(day, index) in data.item.data.data" :key="index">
                  <template v-if="isSameWeekDay(day, data)">
                    <h6 class="text-dark p-4 mb-0">{{ day.totalCount }}</h6>
                  </template>
                </div>
              </template>
            </template>
          </b-table>
        </div>
        <!--end::Table-->
      </template>
      <!--begin::Dummy-->
      <template v-else>
        <div class="row">
          <div class="col-md-6 alert alert-custom alert-light-warning fade show ml-4 my-5" role="alert">
            <div class="alert-icon"><Warning/></div>
            <div class="alert-text text-secondary">{{ $t("ANALYTICS.WARNING_TEXT") }}</div>
          </div>
        </div>
      </template>
      <!--end::Dummy-->
    </div>
    <!--end::Body-->
    <!--begin::Edit Period Date Modal-->
    <EditPeriodDateModal
        @changePeriodDate="changePeriodDate"/>
    <!--end::Edit Period Date Modal-->
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_OFFICES_LIST, GET_OFFICE, GET_GROUPS_LIST } from "@/core/services/store/management.module";
import { GET_ATTENDANCE_GROUPS, EXPORT_ATTENDANCE_GROUPS } from "@/core/services/store/analytics/attendance.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import Warning from "@/view/layout/svg/Warning";
import EditPeriodDateModal from "@/view/layout/modal/EditPeriodDateModal";

export default {
  name: "GroupsAttendance",
  components: {
    Warning,
    EditPeriodDateModal
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("ANALYTICS.GROUP_ATTENDANCE_TITLE") }]);
    this.$store.dispatch(GET_OFFICES_LIST);
    this.$store.dispatch(GET_GROUPS_LIST, { isVisibleInReports: 1 });
  },
  data() {
    return {
      isTableAvailable: false,
      filters: {
        from: moment(new Date()).add(-6, 'days').format('DD.MM.YYYY'),
        to: moment(new Date()).format('DD.MM.YYYY')
      },
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        }
      },
      filter_period: 1,
      filter_offices: null,
      filter_floors: null,
      filter_groups: null
    }
  },
  watch: {
    filter_offices(val) {
      if (val !== undefined) {
        this.$store.dispatch(GET_OFFICE, val)
      } else {
        this.filter_floors = null;
      }
    },
    filter_floors(val) {
      if (val && val.length > 0) {
        this.isTableAvailable = true;
        this.filters.floors = val;
        this.$store.dispatch(GET_ATTENDANCE_GROUPS, this.filters);
      } else {
        this.isTableAvailable = false;
        this.filter_groups = null;
        delete this.filters.floors;
      }
    },
    filter_groups(val) {
      if (val && val.length > 0) {
        this.filters.groups = val;
        this.$store.dispatch(GET_ATTENDANCE_GROUPS, this.filters);
      } else if (val && val.length === 0 && this.filters.floors) {
        delete this.filters.groups;
        this.$store.dispatch(GET_ATTENDANCE_GROUPS, this.filters);
      } else delete this.filters.groups;
    }
  },
  computed: {
    ...mapGetters([
      'periodList',
      'officesList',
      'office',
      'groupsList',
      'attendanceGroups'
    ]),
    setFields() {
      let dates = [
        { key: 'name', label: this.$t("ANALYTICS.GROUP_TABLE_TITLE") },
        { key: 'types', label: this.$t("ANALYTICS.ASSIGMENT_TABLE_TITLE") }
      ];
      let key = 2;
      let firstDate = moment(this.filters.from, 'DD.MM.YYYY').startOf('day');
      let lastDate = moment(this.filters.to, 'DD.MM.YYYY').startOf('day');
      dates.push({ key: '1', label: moment(firstDate).format('DD.MM.YYYY') });
      while(firstDate.add(1, 'days').diff(lastDate) <= 0) {
        dates.push({ key: key.toString(), label: moment(firstDate).format('DD.MM.YYYY') });
        key++
      }
      return dates;
    }
  },
  methods: {
    setPeriod(val) {
      if (val.id === 1) {
        this.filters.from = moment(new Date()).add(-6, 'days').format('DD.MM.YYYY');
        this.filters.to = moment(new Date()).format('DD.MM.YYYY');
      } else if (val.id === 2) {
        this.filters.from = moment(new Date()).add(-14, 'days').format('DD.MM.YYYY');
        this.filters.to = moment(new Date()).format('DD.MM.YYYY');
      } else if (val.id === 3) {
        this.filters.from = moment(new Date()).add(-29, 'days').format('DD.MM.YYYY');
        this.filters.to = moment(new Date()).format('DD.MM.YYYY');
      } else if (val.id === 4) {
        this.filters.from = moment(new Date()).startOf('month').format('DD.MM.YYYY');
        this.filters.to = moment(new Date()).format('DD.MM.YYYY');
      } else if (val.id === 5) {
        this.filters.from = moment().subtract(1, 'months').startOf('month').format('DD.MM.YYYY');
        this.filters.to = moment().subtract(1, 'months').endOf('month').format('DD.MM.YYYY');
      } else if (val.id === 6) {
        this.$bvModal.show('modal-edit-period-date');
      }
      if (val && val.id !== undefined && val.id !== 6) {
        this.$store.dispatch(GET_ATTENDANCE_GROUPS, this.filters);
      }
    },
    changePeriodDate(newDate) {
      this.filters.from = newDate.from;
      this.filters.to = newDate.to;
      this.$store.dispatch(GET_ATTENDANCE_GROUPS, this.filters);
    },
    onExport() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store.dispatch(EXPORT_ATTENDANCE_GROUPS, { form: this.filters })
          .then(() => {
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          })
    },
    isSameWeekDay(item, data) {
      let label = moment(data.field.label, 'YYYY-MM-DD').startOf('day');
      let day = moment(item.date, 'YYYY-MM-DD').startOf('day');
      if (label.format('YYYY-MM-DD') === day.format('YYYY-MM-DD')) {
        return true;
      }
      return false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .truncate-long-text {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
